import React, { useRef } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

function BulkInvoice({singleOrder}) {
    const invoiceRef = useRef();
    console.log('======',singleOrder)

    // Function to generate PDF
    const downloadPDF = async () => {
      const element = invoiceRef.current;
      const canvas = await html2canvas(element);
      const data = canvas.toDataURL("image/png");
  
      const pdf = new jsPDF("p", "mm", "a4");
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
  
      pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("invoice.pdf");
    };
  return (
    <div>
      {/* Invoice Content */}
      <div ref={invoiceRef} style={{ padding: "20px", border: "1px solid #ddd", maxWidth: "800px", margin: "auto" }}>
        <h1 style={{ textAlign: "center" }}>Invoice</h1>
        <p>Invoice Number: <strong>#{singleOrder?.numericOrderId}</strong></p>
        <p>Date: <strong>{new Date(singleOrder?.createdAt).toLocaleDateString()}</strong></p>
        <table style={{ width: "100%", borderCollapse: "collapse", marginTop: "20px" }}>
          <thead>
            <tr>
              <th style={{ border: "1px solid #ddd", padding: "8px" }}>Item</th>
              <th style={{ border: "1px solid #ddd", padding: "8px" }}>Mrp</th>
              <th style={{ border: "1px solid #ddd", padding: "8px" }}>Quantity</th>
              <th style={{ border: "1px solid #ddd", padding: "8px" }}>Discount</th>
              <th style={{ border: "1px solid #ddd", padding: "8px" }}>Total</th>
            </tr>
          </thead>
          <tbody>
            {
                singleOrder && singleOrder.products.map((product)=>(
                    <tr>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>{product.name}</td>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>₹{product.mrp}</td>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>{product.quantity}</td>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>{product.discount}%</td>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>₹{product.subtotal}</td>
                  </tr>
                ))
            }
          </tbody>
          <tfoot>
          <tr>
              <td colSpan="4" style={{ borderLeft: "1px solid #ddd", padding: "8px", textAlign: "right", }}>Shipping Cost</td>
              <td style={{ border: "1px solid #ddd", padding: "8px" }}>₹{singleOrder?.shippingCost||0}</td>
            </tr>
            <tr>
              <td colSpan="4" style={{ borderLeft: "1px solid #ddd",borderBottom:'1px solid #ddd', padding: "8px", textAlign: "right" }}>Total</td>
              <td style={{ border: "1px solid #ddd", padding: "8px" }}>₹{singleOrder?.totalPayableAmount}</td>
            </tr>
          </tfoot>
        </table>
      </div>

      {/* Download Button */}
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <button onClick={downloadPDF} style={{ padding: "10px 20px", fontSize: "16px", cursor: "pointer" }}>
          Download PDF
        </button>
      </div>
    </div>
  )
}

export default BulkInvoice