import React from "react";
import {
  Grid,
  TextField,
  FormControl,
  MenuItem,
  Button,
} from "@material-ui/core";
import {
  //  Alert, Chip,
  Dialog,
  //    DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Chip from "../../ui-component/extended/Chip";
// import { useMemo } from 'react';

function CancellationAlert({
  open,
  setOpen,
  cancelData,
  role,
  phoneNumber,
  todaysOrderID,
}) {
  const label = { inputProps: { "aria-label": "Switch demo" } };

  const [status, setStatus] = useState([]);
  const [cancelDetail, setCancelDetail] = useState({
    remark: "",
    recentStatus: "",
  });

  const [ticketID, setTicketID] = useState(null);

  const [disableBtn, setDisableBtn] = useState(false);
  const [successRes, setSuccessRes] = useState(true);
  const [verifiedOrder, setVerifiedOrder] = useState(true);
  const [notVerifiedOrder, setNotVerifiedOrder] = useState(false);
  const [changeStatus, setChangeStatus] = useState(0);
  const [orderID, setOrderID] = useState();
  const [orderIDList, setOrderIDList] = useState([]);

  const [todayOrderData, setTodayOrderData] = useState([]);

  const chip = (status) => {
    if (status === 1) {
      return <Chip label="Processing" size="small" chipcolor="primary" />;
    } else if (status === 2) {
      return <Chip label="Canceled" size="small" chipcolor="orange" />;
    } else if (status === 3) {
      return <Chip label="Complete" size="small" chipcolor="success" />;
    } else if (status === 4) {
      return <Chip label="RTO" size="small" chipcolor="orange" />;
    } else if (status == 8) {
      return <Chip label="Splitted" size="small" chipcolor="secondary" />;
    }
  };

  const accessNumber = [
    "8448300516",
    "8076096127",
    "9773831187",
    "8882049957",
    "9719836187",
    "7048952168",
    "9891434580",
    "7011197257",
    "9650518684",
    "8368204349",
    "7011644021",
    "8810628760",
    "9310128750",
    "7827843126",
    "8851954552",
    "9354815727",
    "9625342361",
    "8076096127",
    "9315481432",
    "8076833686",
    "8851162831",
    '9811068834',
    '7011976531',
    '7088578757'
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCancelDetail({ ...cancelDetail, [name]: value });
  };

  const handleClose = () => {
    setOpen(false);
    setSuccessRes(true);
    setTicketID(null);
    setOrderID(null);
    setOrderIDList([]);
    setChangeStatus(0);
  };

  const updateTicket = (e) => {
    e.preventDefault();
    setDisableBtn(true);
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_PATH}/role4/createTicketForOrder`,
      data: {
        status: cancelDetail.recentStatus,
        remark: cancelDetail.remark,
        orderIds: orderIDList,
        phoneNumber: cancelData.phoneNumber,
      },
    })
      .then((res) => {
        setTicketID(res.data.data?.ticket?.numericId);
        setDisableBtn(false);
        setSuccessRes(false);
        setCancelDetail({
          remark: "",
          recentStatus: "",
        });
        alert("Ticket Raised Successfully");
        window.location.reload();
      })
      .catch((err) => {
        alert("Something Went Wrong");
        setDisableBtn(false);
      });
  };

  const CheckOrder = (SelectedOrder, index) => {
    if (
      SelectedOrder.hasOwnProperty("isVerified") === false ||
      SelectedOrder.isVerified === true
    ) {
      return (
        <Switch
          {...label}
          onChange={() =>
            getVerifyProduct(
              SelectedOrder._id,
              false,
              SelectedOrder.source,
              SelectedOrder.phoneNumber,
              index
            )
          }
          checked={verifiedOrder[SelectedOrder._id]}
          disabled={accessNumber.includes(phoneNumber) ? false : true}
        />
      );
    } else if (SelectedOrder.isVerified === false) {
      return (
        <Switch
          {...label}
          onChange={() =>
            getVerifyProduct(
              SelectedOrder._id,
              true,
              SelectedOrder.source,
              SelectedOrder.phoneNumber,
              index
            )
          }
          checked={verifiedOrder[SelectedOrder._id]}
          disabled={accessNumber.includes(phoneNumber) ? false : true}
        />
      );
    }
  };

  const getTodaysOrder = () => {
    if (todaysOrderID !== "") {
      axios({
        method: "get",
        url: `${process.env.REACT_APP_PATH}/role1/getAllOrdersOfSellersForToday?orderId=${todaysOrderID}`,
      })
        .then((res) => {
          setTodayOrderData(res.data.data);
          getMultipleOrderID(res.data.data);
        })
        .catch((err) => {
          alert("Not Able To Get Today Order");
        });
    } else {
      console.log("");
    }
  };

  const getVerifyProduct = (id, isVerified, source, number, index) => {
    setOrderID(id);

    axios({
      method: "POST",
      url: `${process.env.REACT_APP_PATH}/role4/verifyProductOrder`,
      data: {
        orderId: id,
        isVerified: !verifiedOrder[id],
      },
    })
      .then((res) => {
        setVerifiedOrder((prev) => ({
          ...prev,
          [id]: res.data.data?.isVerified,
        }));
        // setNotVerifiedOrder(res.data.data?.isVerified);
        setChangeStatus(changeStatus + 1);

        // alert("Status Changed Successfully");
        // window.location.reload();
      })
      .catch((err) => {
        alert("Something Went Wrong");
      });
  };

  useEffect(() => {
    todayOrderData.map((order, index) => {
      setVerifiedOrder((prev) => ({ ...prev, [order._id]: order.isVerified }));
      // setNotVerifiedOrder(order.isVerified);
    });
  }, [todayOrderData]);

  useEffect(() => {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_PATH}/role4/getAuditResponseStatus`,
    })
      .then((res) => {
        setStatus(res.data.data);
      })
      .catch((err) => {
        alert("Not Getting Cancel Status");
      });
  }, []);

  const getMultipleOrderID = (orders) => {
    let tempArr = [];
    orders.map((el, index) => {
      if (!el.isVerified) {
        let orderID = el._id;
        return tempArr.push(orderID);
      } else {
        return tempArr.filter((item) => {
          return item == el._id;
        });
      }
    });
    setOrderIDList(tempArr);
  };

  useEffect(() => {
    const verifiedArray = todayOrderData.map((item) => item.isVerified);
    const newArray = verifiedArray.indexOf(false);
    if (newArray != -1 || verifiedArray.length == 0) {
      setNotVerifiedOrder(false);
    } else {
      setNotVerifiedOrder(true);
    }
  }, [todayOrderData]);

  useEffect(() => {
    getTodaysOrder();
  }, [todaysOrderID, changeStatus]);

  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          <h2>Raise Ticket</h2>
          {notVerifiedOrder ? (
            <Button
              onClick={handleClose}
              variant="outlined"
              style={{ position: "absolute", right: "2rem", top: "1.5rem" }}
            >
              Close
            </Button>
          ) : (
            ""
          )}{" "}
        </DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Grid container spacing={1}>
              {todayOrderData.length > 0 ? (
                <>
                  <Grid item lg={12} mb="2rem">
                    <TableContainer
                      sx={{
                        minWidth: "400",
                      }}
                    >
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            <TableCell align="center">Ship Order Id</TableCell>
                            <TableCell align="center">Name</TableCell>
                            <TableCell align="center">Phone Number</TableCell>
                            <TableCell align="center">Total Amount</TableCell>
                            <TableCell align="center">Status</TableCell>
                            <TableCell align="center">
                              Audit Verification
                            </TableCell>
                            <TableCell align="center">Action</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody
                          style={{
                            userSelect:
                              role == 1 || role == 2 || role == 4
                                ? "text"
                                : "none",
                          }}
                        >
                          {todayOrderData.length > 0
                            ? todayOrderData.map((order, index) => {
                                return (
                                  <TableRow
                                    sx={{ py: 3 }}
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={index}
                                  >
                                    <TableCell
                                      align="center"
                                      style={{
                                        color:
                                          order.paymentMethod == 1
                                            ? "#000"
                                            : "green",
                                      }}
                                    >
                                      {order.shiprocketOrderId}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      style={{
                                        color: order.isBeuPartnerOrder
                                          ? "red"
                                          : "#000",
                                      }}
                                    >
                                      {order.name}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      style={{
                                        color: order.isBeuPartnerOrder
                                          ? "red"
                                          : "#000",
                                      }}
                                    >
                                      {order.phoneNumber}
                                    </TableCell>
                                    <TableCell align="center">
                                      {Math.round(order.totalPayableAmount)}
                                    </TableCell>
                                    <TableCell align="left">
                                      {/* {order.status} */}
                                      {order.status === 3 && chip(3)}
                                      {order.status === 2 && chip(2)}
                                      {order.status === 4 && chip(4)}
                                      {order.status === 8 && chip(8)}
                                      {order.awbNumber !== undefined &&
                                      order.status === 1 ? (
                                        <Chip
                                          label="In Transit"
                                          size="small"
                                          chipcolor="primary"
                                        />
                                      ) : order.status === 1 &&
                                        order.awbNumber === undefined ? (
                                        chip(1)
                                      ) : null}

                                      {/* {order.status === 1 && (
                          <Chip
                            label="Processing"
                            size="small"
                            chipcolor="primary"
                          />
                        )} */}
                                    </TableCell>
                                    {role == 1 ||
                                    role == 2 ||
                                    role == 4 ||
                                    role == 17 ? (
                                      <>
                                        <TableCell>
                                          {CheckOrder(order, index)}
                                          {/* {
                                                                                    order.isVerified === true ?

                                                                                        <Switch {...label} onChange={() => getVerifyProduct(order._id, false, order.source, order.phoneNumber, index)} checked={ true } disabled={phoneNumber == 8448300516 || phoneNumber == 8076096127 || phoneNumber == 9773831187 || phoneNumber == 9719836187 ? false : true} />
                                                                                        :
                                                                                        <Switch {...label} onChange={() => getVerifyProduct(order._id, true, order.source, order.phoneNumber, index)} checked={ false } disabled={phoneNumber == 8448300516 || phoneNumber == 8076096127 || phoneNumber == 9773831187 || phoneNumber == 9719836187 ? false : true} />
                                                                                } */}
                                        </TableCell>
                                      </>
                                    ) : null}

                                    <TableCell align="center">
                                      <Link
                                        to={`/orderdetails/${order._id}/false`}
                                        style={{
                                          textDecoration: "none",
                                          backgroundColor: "#f6f6f6",
                                          padding: "10px 15px",
                                        }}
                                        target="_blank"
                                      >
                                        Details
                                      </Link>
                                    </TableCell>
                                  </TableRow>
                                );
                              })
                            : null}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </>
              ) : (
                ""
              )}
            </Grid>
            <form onSubmit={(e) => updateTicket(e)}>
              <Grid container spacing={1}>
                <Grid item lg={6} xs={4} md={4}>
                  <FormControl>
                    <TextField
                      value={cancelDetail.recentStatus}
                      select
                      onChange={(e) => handleChange(e)}
                      label="Cancel Reason"
                      name="recentStatus"
                      required
                      style={{ width: "200px" }}
                    >
                      {status.length &&
                        status.map((cur, ind) => {
                          return (
                            <MenuItem value={cur.id} key={ind}>
                              {cur.status}
                            </MenuItem>
                          );
                        })}
                    </TextField>
                  </FormControl>
                </Grid>
                <Grid item lg={6}>
                  <TextField
                    required
                    label="remark"
                    style={{ width: "200px" }}
                    name="remark"
                    onChange={(e) => handleChange(e)}
                    value={cancelDetail.remark}
                  />
                </Grid>
                <Grid item lg={12}>
                  <Button
                    type="submit"
                    disabled={disableBtn}
                    variant="contained"
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default CancellationAlert;
