import React, { useState } from "react";
import XLSX from "xlsx";
import { Grid, MenuItem, Select, Typography } from "@material-ui/core";
import axios from "axios";
import { LoadingButton } from "@mui/lab";
import { CSVLink } from "react-csv";

// const UploadInventory = () => {
const SheetJSFT = [
  "xlsx",
  "xlsb",
  "xlsm",
  "xls",
  "xml",
  "csv",
  "txt",
  "ods",
  "fods",
  "uos",
  "sylk",
  "dif",
  "dbf",
  "prn",
  "qpw",
  "123",
  "wb*",
  "wq*",
  "html",
  "htm",
]
  .map(function (x) {
    return "." + x;
  })
  .join(",");

const headers = [
  { label: "orderId", key: "orderId" },
  { label: "EANCode", key: "EANCode" },
  { label: "margin", key: "margin" },
];
const headers2 = [
  { label: "invoiceId", key: "invoiceId" },
  { label: "awbNumber", key: "awbNumber" },
  { label: "status", key: "status" },
];

const UploadBulkMargin = () => {
  const [file, setFile] = useState({});
  const [fileForAwb, setFileForAwb] = useState({});
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [disable, setDisable] = useState(true);
  const [disable2, setDisable2] = useState(true);
  const [orderType, setOrderType] = useState("BULK");

  const [products, setProducts] = useState([]);

  const data = [
    { orderId: "123", margin: "10", EANCode: "123456789" },
    { orderId: "124", margin: "20", EANCode: "123456789" },
    { orderId: "125", margin: "30", EANCode: "123456789" },
    { orderId: "126", margin: "40", EANCode: "123456789" },
  ];
  const data2 = [
    { invoiceId: "EMER/24/25", awbNumber: "208945151", status: "RTO" },
    { invoiceId: "EMER/24/25", awbNumber: "208945151", status: "Cancelled" },
    { invoiceId: "EMER/24/25", awbNumber: "208945151", status: "Delivered" },
  ];

  const handleChange = (e) => {
    const files = e.target.files;
    if (files && files[0]) {
      setFile(files[0]);
      setDisable(false);
    }
  };
  const handleChange2 = (e) => {
    const files = e.target.files;
    if (files && files[0]) {
      setFileForAwb(files[0]);
      setDisable2(false);
    }
  };

  const handleFile = () => {
    setLoading(true);

    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    reader.onload = (e) => {
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, {
        type: rABS ? "binary" : "array",
        bookVBA: true,
        cellDates: true,
      });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws);
      // setProducts(data)
      /* Update state */
      axios
        .post(process.env.REACT_APP_PATH + "/sales/uploadMargin", {
          data: data,
          orderType: orderType,
        })
        .then((res) => {
          if (res?.data?.success) {
            window.alert("File Uploaded Successfully");
            setLoading(false);
          } else {
            setLoading(false);
            window.alert(res?.data || "Something went wrong");
          }
        })
        .catch((err) => {
          console.log("error", err);
          window.alert(err?.response?.data?.message || "Something went wrong");
        });
    };
    if (rABS) {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsArrayBuffer(file);
    }
  };

  // const uploadProducts = () => {
  //   setLoading(true);
  //   setDisable(true);
  //   console.log('proroorr',products)
  //   products.forEach(async (item) => {
  //     let res = await addproduct(item);
  //     console.log("ress of product add", res);
  //     console.log("done");
  //   });
  //   setLoading(false);
  //   setDisable(false);
  // };

  const categoryId ={
    Hair:1
  }

  const addproduct = async (data) => {
    let product = {};
    product.images = new Array(data?.images||"",data?.images||"",data?.images||"");
    product.name=data.name
    product.brand = data?.brand;
    product.brandId = data?.brandId;
    product.leafCategory = data?.leafCategory;
    product.leafCategoryId = data?.leafCategoryId;
    product.category = data?.category;
    product.categoryId = data?.categoryId;
    product.subcategory = data?.subcategory;
    product.subcategoryId = data?.subcategoryId;
    product.isAvailable = data?.isAvailable;
    product.EANCode = data?.EANCode;
    product.baseDiscount = 0;
    product.onlineBulkDiscount = 0;
    product.flashSaleDiscount = 0;
    product.costPrice = data?.mrp;
    product.mrp = data?.mrp;
    product.price = data?.price;
    product.quantity = 0;
    product.quantityType = data?.quantityType||'';
    product.masterEANCode = data?.masterEANCode;
    product.HSNCode = data?.HSNCode;
    product.isRetail = Boolean(data?.isRetail);
    console.log('producttt',product)
    // if (
    //   leafCategoryDetails.name.length > 0 &&
    //   brandDetail.name.length > 0 &&
    //   EANCode.length > 0 &&
    //   leafSubCategory.name.length > 0 &&
    //   leafDetails.name.length > 0 &&
    //   masterEANCode.trim().length > 0
    // )

    // {
      axios
        .post(process.env.REACT_APP_PATH + "/products/addProducts", product)
        .then((response) => {
          console.log("response", response);
          if (response.data.success) {
          } else {
          }
        })
        .catch((error) => {
          //console.log("Create error", error);
        });
    }
    // else {
    //   window.alert("Please Enter All Fields");
    // }
  // };

  const handleFile2 = () => {
    setLoading2(true);

    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    reader.onload = (e) => {
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, {
        type: rABS ? "binary" : "array",
        bookVBA: true,
        cellDates: true,
      });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws);
      /* Update state */
      axios
        .post(
          process.env.REACT_APP_PATH + "/sales/updateAwbNumberInBulkOrders",
          {
            data: data,
          }
        )
        .then((res) => {
          if (res?.data?.success) {
            window.alert("File Uploaded Successfully");
            setLoading2(false);
          } else {
            setLoading2(false);
            window.alert(res?.data || "Something went wrong");
          }
        })
        .catch((err) => {
          console.log("error", err);
          window.alert(err?.response?.data?.message || "Something went wrong");
        });
    };
    if (rABS) {
      reader.readAsBinaryString(fileForAwb);
    } else {
      reader.readAsArrayBuffer(fileForAwb);
    }
  };

  return (
    <Grid container>
      <>
        <Grid item xs={12} lg={4} gap={2}>
          <div
            style={{
              border: "2px solid lightGrey",
              borderRadius: 5,
              marginLeft: 10,
              padding: 10,
            }}
          >
            <Typography variant="h3">Upload an Excel File</Typography>
            <br />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <input
                type="file"
                className="form-control"
                id="file"
                accept={SheetJSFT}
                onChange={handleChange}
              />
              <Select
                value={orderType}
                onChange={(e) => setOrderType(e.target.value)}
                label="Order Type"
              >
                <MenuItem value={"BULK"}>Bulk Order</MenuItem>
                <MenuItem value={"CRM"}>Crm Order</MenuItem>
              </Select>
              <LoadingButton
                disabled={disable}
                loading={loading}
                loadingIndicator="Uploading..."
                onClick={handleFile}
                variant="contained"
              >
                Upload
              </LoadingButton>
              {/* <LoadingButton
                disabled={disable}
                loading={loading}
                loadingIndicator="Uploading..."
                onClick={uploadProducts}
                variant="contained"
              >
                Upload Products
              </LoadingButton> */}
            </div>
            <br />
          </div>
        </Grid>
        <Grid item xs={12} lg={7}>
          <div
            style={{
              borderRadius: 5,
              marginRight: 10,
              padding: 10,
            }}
          >
            <CSVLink data={data} headers={headers} filename={"BulkMargin.csv"}>
              Download Sample File
            </CSVLink>
          </div>
        </Grid>
      </>
      <div style={{ marginTop: 30, width: "100%", display: "flex" }}>
        <Grid item xs={12} lg={4} gap={2}>
          <Typography style={{ marginLeft: 10, marginBottom: 10 }}>
            Update Awb Number In Bulk Orders
          </Typography>
          <div
            style={{
              border: "2px solid lightGrey",
              borderRadius: 5,
              marginLeft: 10,
              padding: 10,
            }}
          >
            <Typography variant="h3">Upload an Excel File</Typography>
            <br />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <input
                type="file"
                className="form-control"
                id="file"
                accept={SheetJSFT}
                onChange={handleChange2}
              />
              <LoadingButton
                disabled={disable2}
                loading={loading2}
                loadingIndicator="Uploading..."
                onClick={handleFile2}
                variant="contained"
              >
                Upload
              </LoadingButton>
            </div>
            <br />
          </div>
        </Grid>
        <Grid item xs={12} lg={7}>
          <div
            style={{
              borderRadius: 5,
              marginRight: 10,
              padding: 10,
              marginTop: 20,
            }}
          >
            <CSVLink
              data={data2}
              headers={headers2}
              filename={"SampleAwbNumberInBulkOrders.csv"}
            >
              Download Sample File
            </CSVLink>
          </div>
        </Grid>
      </div>
    </Grid>
  );
};

export default UploadBulkMargin;
