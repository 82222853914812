 import { Orders } from "./ordersCrm/Orders";
// import { Booking } from "./Booking/Booking";
import { Seller } from "./Seller/Seller";
// import { Customer } from "./Customer/Customer";
import { Product } from "./Product";
import { Reports } from "./Reports";
import { Zoom } from "./Zoom";
import { AdminTab } from "./Admin/index"
// import { PurchaseOrder } from "./PurchaseOrder";
// import { forms } from "./forms";
import { AdminReports } from './AdminReports'
import { Inventory } from "./Inventory"
// import { fulfilled } from "./Orders"
import { FinancialReports } from "./FinancialReports";
import { Dashboard } from "./Dashboard/index";
import { vendor } from "./Vendor";
import { Role12Reports } from "./Role12Reports";
import { MainDashboard } from "./MainDashboard";
import { Ops } from "./ops";
import { CouponRelated } from "./Coupon";
import { MarketingTab } from "./Marketing Reports/index"
import { fcAdmin } from "./FcAdmin"
import { Packing } from "./PackingOrder";
import { Bulk } from "./Bulk";
import { BulkOrders } from "./AllBulkOrders";
import { getDecryptDataFromLocal } from "../utils/encrypt";


//-----------------------|| MENU ITEMS ||-----------------------//
var item = []
var role = getDecryptDataFromLocal('setRole')
let cohortTeam = localStorage.getItem('cohortTeam')
let phoneNumber = getDecryptDataFromLocal('phoneNumber')

console.log("seller role===>", role)


// var role = 12

//admin
if (role == 1) {
  item = [MainDashboard, Orders, Seller, Product, Reports, MarketingTab, Zoom, AdminTab, AdminReports, Dashboard, FinancialReports, Ops, Inventory, fcAdmin, vendor, CouponRelated,Bulk,BulkOrders]
  // item = [MainDashboard, Orders, Seller, Product, Reports, Zoom, AdminTab, AdminReports, Dashboard, FinancialReports, Ops, Inventory, vendor]
}

//ops
else if (role == 2) {
  if(['9319132363','9907125180','8826956978','9911579990'].includes(phoneNumber)){
    item = [Orders, Seller, Product, Reports, Inventory, MarketingTab, FinancialReports, Zoom, Dashboard, Ops, AdminTab, fcAdmin, vendor, CouponRelated,Bulk,BulkOrders]
  } else if(phoneNumber=='8448300516'){
    item = [Orders, Seller, Product, Reports, Inventory, MarketingTab, FinancialReports, Zoom, Dashboard, Ops, AdminTab, vendor, CouponRelated,Bulk,BulkOrders]
  }  
  else {
    item = [Orders, Seller, Product, Reports, Inventory, MarketingTab, FinancialReports, Zoom, Dashboard, Ops, AdminTab, vendor, CouponRelated,BulkOrders]
  }
}

//
else if (role == 3) {
  item = [Orders, Seller, Product, Reports, FinancialReports, Dashboard,Bulk]
}
else if (role == 4) {
  if (phoneNumber === "9891774071" ) {
    item = [Orders, Seller, Product, Reports, Inventory, Dashboard, CouponRelated, MarketingTab, fcAdmin,Bulk,BulkOrders]
  }
  else if (['8076096127','8178277221','9811068834'].includes(phoneNumber)) {
    item = [Orders, Seller, Product, Reports, AdminTab,Inventory, Dashboard, CouponRelated, fcAdmin, FinancialReports,Bulk,BulkOrders]
  }

  else if(phoneNumber=='9667530528') {
    item = [Orders, Seller, Product, Reports, Inventory, Dashboard, CouponRelated, fcAdmin,Bulk,BulkOrders,Ops,AdminTab]
  }else if(['9873804039','9899911403','8384080103','8851763878','8969708515'].includes(phoneNumber)){
    item = [Orders, Seller, Product, Reports, Inventory, Dashboard, CouponRelated, fcAdmin,FinancialReports,Bulk,BulkOrders]
  }else if(['8882954273','7065923736'].includes(phoneNumber)){
    item = [Orders, Seller, Product, Reports, Inventory, Dashboard, CouponRelated, fcAdmin,AdminTab,Bulk,BulkOrders]
  }
  else {
    item = [Orders, Seller, Product, Reports, Inventory, Dashboard, CouponRelated, fcAdmin,Bulk,BulkOrders]
  }
}

//sales
else if (role == 5 && ['9359536247', '9119008412', '9027110892', '7007206979', '9315116177','7701974309'].includes(phoneNumber)) {
  item = [Seller, Dashboard, Product, Inventory,Bulk,BulkOrders]
}

else if (role == 5 && cohortTeam == 'true') {
  if (phoneNumber == "8368765621") {
    item = [Orders, Seller, Dashboard, Product, Inventory,Bulk,BulkOrders]
  } else {
    item = [Orders, Seller, Dashboard, Product,Bulk,BulkOrders]
  }
} else if (role == 5 && cohortTeam == 'false') {
  item = [Seller, Dashboard, Product,Bulk,BulkOrders]
}

else if (role == 6) {
  item = [Orders, Seller, Product, Reports]
}

//trainers

else if (role == 7) {
  item = [Orders, Seller, Product, Reports, Zoom, AdminTab]
}
else if (role == 8) {
  item = [vendor]
}
else if (role == 9) {
  item = [Orders, Seller, Product]
}
else if (role == 10) {
  item = [AdminTab]
}
else if (role == 12) {
  item = [Orders, Product, Zoom, AdminTab, Role12Reports, Dashboard]
}
else if (role == 13) {
  if(['7701974309','8383908752'].includes(phoneNumber)){
    item = [Orders, Seller, Product, Reports, Zoom, Dashboard, AdminTab,Bulk,BulkOrders]
  }else{
    item = [Orders, Seller, Product, Reports, Zoom, Dashboard, AdminTab,BulkOrders,Ops]
  }
}
else if (role == 14) {

  if (['9870251244', "8851245596", "9310848352", "9911579990", "7982526137", "9990619120", "9560382051",'8368535431','8750022180'].includes(phoneNumber)) {
    item = [Orders, Seller, Product, Reports, Zoom, Dashboard, Ops, Inventory,Bulk,BulkOrders]
    
  }
  else if(['9560382051'].includes(phoneNumber)){
    item = [Orders, Seller, Product, Reports, Zoom, Dashboard,AdminTab,Ops, Inventory,Bulk,BulkOrders]
  } else if(['7838932786'].includes(phoneNumber)){
    item = [Orders, Seller, Product, Reports, Zoom, Dashboard, AdminTab, Ops, Inventory,Bulk,BulkOrders,FinancialReports]
  } 
  
  else {
    item = [Orders, Seller, Product, Reports, Zoom, Dashboard, AdminTab,Ops,Inventory,Bulk,BulkOrders]
  }
}
else if (role == 15) {
  item = [Dashboard]
}
else if (role == 16) {
  item = [Orders, Seller, Product, Reports, Zoom, AdminTab, Inventory, MarketingTab]
}
else if (role == 17) {
  item = [Orders, Seller, Product, Reports, Dashboard, Ops, AdminTab, FinancialReports, Inventory,BulkOrders]
  if (['9268096291', '9891769967', '8076833686'].includes(phoneNumber)) {
    item = [Orders, Seller, Product, Reports, Dashboard, Ops, AdminTab, MarketingTab, FinancialReports, Inventory,BulkOrders]
  } 
   if(["7532035127",'8076096127'].includes(phoneNumber)){
    item = [Orders, Seller, Product, Reports, Dashboard, Ops, AdminTab, FinancialReports, Inventory, fcAdmin,Bulk,BulkOrders]
  }
}
else if (role == 18) {
  item = [Orders, Seller, Product, Reports, Zoom, Dashboard, AdminTab, Inventory]
}
else if (role == 19) {
  item = [Packing]
}

const menuItems = {
  items: item
};
// bookiing
export default menuItems;
